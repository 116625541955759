<script setup lang="ts">

</script>

<template>
  <UDashboardLayout class="mt-[43px]">
    <KeepAlive>
      <LazyModalPlayer />
    </KeepAlive>
    <UDashboardPanel
      :width="250"
      :resizable="{ min: 200, max: 300 }"
      collapsible
    >
      <div class="mx-auto flex h-[--header-height] max-w-7xl items-center justify-between gap-3 px-4 sm:px-6 md:hidden lg:px-8">
        <NuxtLink to="/" aria-label="Logo" class="flex flex-shrink-0 items-end gap-1.5 text-xl font-bold text-gray-900 dark:text-white">
          <span class="font-orbitron  text-primary">Dynasty Data Lab</span>
        </NuxtLink>
      </div>
      <UDashboardNavbar
        class="!border-transparent md:hidden"
        :ui="{ left: 'flex-1' }"
      />
      <LineupSidebar />
    </UDashboardPanel>
    <slot />
  </UDashboardLayout>
</template>
