<script setup lang="ts">
const LineupStore = useLineupStore()
const { hasData, playerChanges, byeChanges, injuryChanges, flexChanges, userId } = storeToRefs(LineupStore)

const links = computed(() => [{
  id: 'home',
  label: 'Home',
  icon: 'i-heroicons-home',
  to: '/rosters/',
  tooltip: {
    text: 'Home',
  },
}, {
  id: 'players',
  label: 'Players',
  icon: 'i-mdi-human-capacity-increase',
  to: '/rosters/players',
  disabled: !hasData.value,
  tooltip: {
    text: 'Started Players Lists',
  },
}, {
  id: 'lineup',
  label: 'Lineup',
  icon: 'i-mdi-strategy',
  to: '/rosters/lineups',
  disabled: !hasData.value,
  tooltip: {
    text: 'Lineup Manager',
  },
}, {
  id: 'games',
  label: 'Games',
  icon: 'i-material-symbols-sports-football',
  to: '/rosters/games',
  disabled: !hasData.value,
  tooltip: {
    text: 'Games Importance',
  },
}, {
  id: 'waiver',
  label: 'Waiver',
  icon: 'i-material-symbols-person-search-outline',
  to: '/rosters/waiver',
  disabled: !hasData.value,
  children: [{
    label: 'Trending',
    to: '/rosters/waivers/trending',
    disabled: !hasData.value,
  }, {
    label: 'Ownership',
    disabled: !hasData.value,
    to: '/rosters/waivers/ownership',
  }],
  tooltip: {
    text: 'Waiver Wire Manager',
  },
}, {
  id: 'suggestions',
  label: 'Suggestions',
  icon: 'i-material-symbols-swap-horiz',
  to: '/rosters/suggestions',
  badge: (injuryChanges.value.length + byeChanges.value.length + playerChanges.value.length) || undefined,
  disabled: !hasData.value,
  children: [{
    label: 'Bye',
    to: '/rosters/suggestions/bye',
    badge: byeChanges.value.length ? byeChanges.value.length : undefined,
    disabled: !hasData.value,
    exact: true,
  }, {
    label: 'Start Rate',
    badge: playerChanges.value.length ? playerChanges.value.length : undefined,
    disabled: !hasData.value,
    to: '/rosters/suggestions/start',
  }, {
    label: 'Injury',
    badge: injuryChanges.value.length ? injuryChanges.value.length : undefined,
    disabled: !hasData.value,
    to: '/rosters/suggestions/injury',
  }, {
    label: 'Flex',
    badge: flexChanges.value.length ? flexChanges.value.length : undefined,
    disabled: !hasData.value,
    to: '/rosters/suggestions/flex',
  }],
  tooltip: {
    text: 'Swaps of players for your team',
  },
}],
)

const footerLinks = [{
  label: 'Exit',
  icon: 'i-material-symbols-exit-to-app',
  to: '/',
}]
</script>

<template>
  <UDashboardSidebar>
    <UButton v-if="userId" color="gray" variant="ghost" class="w-full bg-gray-50 dark:bg-gray-800">
      <UAvatar :src="`https://sleepercdn.com/avatars/thumbs/${userId.avatar}`" size="2xs" />
      <span class="truncate font-semibold text-gray-900 dark:text-white">{{ userId.display_name }}</span>
    </UButton>
    <UDashboardSidebarLinks :links="links" :ui="{ label: 'text-base' }" />
    <UDivider />
    <div class="flex-1" />
    <UDashboardSidebarLinks :links="footerLinks" />
    <UDivider class="sticky bottom-0" />
  </UDashboardSidebar>
</template>
